import React, { useEffect, useState, useRef } from "react";
import Shapes from "./Shapes";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  SG_START_BRIEF_SURVEY,
  NEW_VALIDATION_TOKEN,
  RESET_VALIDATION_TOKEN,
  CLEAR_ALL,
  SG_POST_BRIEF_RESPONSE,
  UPDATE_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE,
} from "constants/actions";

import {
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

import Intro from "./Intro";
import SurveyMessage from "./Message";
import styled from "styled-components";
import { Icon, Loader } from "semantic-ui-react";
import Language from "./Language";
import LogoAnimation from "survey/SurveyComponents/LogoAnimations";

import CategoryQuestion from "./Categories";

import NoToken from "./NoToken";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation";

import "../survey.css";
import QuestionTemplate from "./Questions";
import CommentTemplate from "./Comments";
import OtpExistingDataFound from "survey/SurveyComponents/OtpExistingData";

import Agreement from "survey/SurveyComponents/Agreement";
import End from "./End";

import { RunRules } from "utilities/FeedbackRules";
import CommentsFeedback from "./CommentsFeedback";

const categoryType = "category";
const questionsType = "questions";
const commentsType = "comments";
const submitButton = "submitButton"
// // const categoryType = "category"
// function setQuestionIds(questions) {

//     let questionId = 1;

//     // Find the maximum number of questions in any factor
//     let maxQuestions = 0;
//     for (let dimension of questions.dimensions) {
//         for (let factor of dimension.factors) {
//             maxQuestions = Math.max(maxQuestions, factor.questions.length);
//         }
//     }

//     // Loop through each question position
//     for (let questionIndex = 0; questionIndex < maxQuestions; questionIndex++) {
//         // Loop through each dimension
//         for (let dimension of questions.dimensions) {
//             // Loop through each factor within the dimension
//             for (let factor of dimension.factors) {
//                 // If the current question index exists in this factor, set its id
//                 if (factor.questions.length > questionIndex) {
//                     factor.questions[questionIndex].id = questionId++;
//                 }
//             }
//         }
//     }

// 	console.log(questions)
// }

function hasMatch(list1, list2) {
  return list1.some(item => list2.includes(item));
}

const arrange_Questions = (questions) => {
  // setQuestionIds(questions)
  if (questions?.dimensions) {
    return questions.dimensions
      .map((item, i) => {
        return item.factors.map((factor, f) => {
          return factor.questions.map((q, idx) => {
            return {
              question: q.q,
              dimension: i,
              factor: f,
              q: idx,
              binary: q?.binary,
              role: q.role,
              reverse: q.reverse,
              order: q.id,
              fr: q.fr,
            };
          });
        });
      })
      .flat()
      .flat()
      .sort((a, b) => a.order - b.order);
  }
};

const OtpSurvey = () => {
  const dispatch = useDispatch();
  const deviceDetected = deviceDetect();
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const token = params.get("token");
  // token is not query string but a param
  // const VALIDATION_TOKEN = localStorage.getItem("validation_token");
  let [searchParams, setSearchParams] = useSearchParams();
  const direct_language = Number(searchParams.get("language"));

  let { token } = useParams();

  const [direction, setDirection] = useState(1);
  const [showPrev, setShowPrev] = useState(true);
  const [showNext, setShowNext] = useState(false);
  // const [location, setLocation] = useState(0)
  const [showIntro, setShowIntro] = useState(false);
  const [noResponse, setNoResponse] = useState(false);
  const [delayedShow, setDelayedShow] = useState(true);
  const elementRef = useRef(null);

  const [defaultMessage, setDefaultMessage] = useState({
    title: "Survey completed",
    subTitle: "This survey has been successfully submitted",
  });
  //
  const [totalQuestions, setTotalQuestions] = useState({
    total: 0,
    runningTotal: 0,
    questions: 0,
    culture: 0,
    sequence: {},
  });
  const [questions, setQuestions] = useState([]);
  const [categoryQues, setCategoryQues] = useState([]);
  const [employeeCategory, setEmployeeCategory] = useState([]);
  const [employeePermissionExtraDet, setEmployeePermissionExtraDet] = useState(
    []
  );
  //
  const [briefResponse, setBriefResponse] = useState([]);
  const [questionNo, setQuestionNo] = useState(0);
  const [visualQuestionNo, setVisualQuestionNo] = useState(1);
  //
  const [prevResponse, setPrevResponse] = useState({});
  const [checkResponse, setCheckResponse] = useState(false);

  const [checkRole, setCheckRole] = useState(false);

  const [questionAnswers, setQuestionAnswers] = useState({
    categories: {},
    comments: {},
    questions: {},
  });

  const [surveyFeedbackResp, setSurveyFeedbackResp] = useState(false);
  const [surveyPersonalityResp, setSurveyPersonalityResp] = useState({});
  const [surveyModularResp, setSurveyModularResp] = useState(false);

  const [ruleFeedback, setRuleFeedback] = useState(false);
  const [ruleFeedbackComplete, setRuleFeedbackComplete] = useState(true);
  const [askFeedback, setAskFeedback] = useState(false);

  const [thankMessage, setThankMessage] = useState(false);
  const [complete, setComplete] = useState(false);

  const [surveyStatus, setSurveyStatus] = useState(0);
  const [resetAnswers, setResetAnswers] = useState(false);
  const [surveyDeleted, setSurveyDeleted] = useState(false);
  const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);

  // stored persisting response
  const [validationToken, setValidationToken] = useState(false);
  const [briefResponseId, setBriefResponseId] = useState(false);
  const [storedResponseFound, setStoredResponseFound] = useState(false);

  const [primaryCategory, setPrimaryCategory] = useState(false);
  const [neverAskResponse, setNeverAskResponse] = useState(false);
  const [prevFoundResponse, setPrevFoundResponse] = useState(false);

  const [consentProvided, setConsentProvided] = useState(false);
  const [language, setLanguage] = useState(0);
  const [showLanguage, setShowLanguage] = useState(false);

  const [submitError, setSubmitError] = useState(false);

  const [askComments, setAskComments] = useState(false);
  // const [organizationInfo, setOrganizationInfo] = useState(false)

  const { get_brief_response, get_errors } = useSelector(
    (state) => ({
      get_brief_response: state.briefresponse,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (direct_language === 1) {
      setLanguage(direct_language);
    }
  }, [direct_language]);

  // question no based on role skip logic
  // step 1 set role
  // console.log(get_brief_response?.response?.employee_category)
  // console.log(questionAnswers?.categories)
  // step 1 set role
  useEffect(() => {
    // if (questionAnswers?.categories) {
    // 	const CatRes = Object.values(questionAnswers?.categories)
    // 	setCheckRole(CatRes.filter(ct => ct.priority === "secondary")?.[0]?.response)
    // }
    // get_brief_response?.response?.employee_category
    if (get_brief_response?.response?.employee_category) {
      const CatRes = get_brief_response?.response?.employee_category;
      setCheckRole(
        CatRes.filter((ct) => ct.priority === "secondary")?.[0]?.value
      );
    }
  }, [get_brief_response.response.employee_category]);

  useEffect(() => {
    if (get_brief_response?.response?.questions?.comments) {
      if (get_brief_response?.response?.questions?.comments?.length > 0) {
        setAskComments(get_brief_response?.response?.questions?.comments);
      }
    }
    setBriefResponseId(get_brief_response?.id);
  }, [get_brief_response]);

  useEffect(() => {
    if (get_brief_response) {
      setQuestions(arrange_Questions(get_brief_response?.response?.questions));
      setEmployeeCategory(get_brief_response?.response?.employee_category);
      setEmployeePermissionExtraDet(
        get_brief_response?.response?.employee_permission_extra_details
      );
      setCategoryQues(
        get_brief_response?.response?.culture_questions?.sort(
          (a, b) => a?.id - b?.id
        )
      );
    }

    if (get_brief_response?.response?.questions?.language) {
      setShowLanguage(true);
      // console.log(get_brief_response)
    }
  }, [get_brief_response]);

  useEffect(() => {
    setRuleFeedbackComplete(true);
    const RuleRun = RunRules({ questions, get_brief_response });
    // console.log(RuleRun)
    if (RuleRun.length > 0) {
      setRuleFeedbackComplete(false);
    }
    setRuleFeedback(RuleRun);
  }, [get_brief_response, questions]);

  useEffect(() => {
    let _id = "";
    let _response = 0;
    let subfactorFound = false;
    let _subfactor = null;
    const SurveyName = get_brief_response?.response?.survey_name;

    if (get_brief_response?.response?.primary_question) {
      get_brief_response.response.primary_question.map((pq) => {
        _id = pq.id;
        if (pq?.subfactor) {
          subfactorFound = true;
        }
        pq &&
          pq.answers.map((aq) => {
            if (aq?.name === SurveyName) {
              if (subfactorFound) {
                _subfactor = aq?.id;
                _response = aq?.parent_id;
              } else {
                _response = aq?.id;
              }
            }
            return null;
          });
        return null;
      });
    }

    setPrimaryCategory({
      id: _id,
      response: _response,
      subfactor: _subfactor,
      priority: "primary",
    });
  }, [get_brief_response]);

  //skip questions
  useEffect(() => {
    // const rolesList = [];
    const _seq = {};
    // add category question nos to list
    const _prevResp = {};
    const _neverAskResp = {};

    let running_total = 0;
    let total = 0;
    let CatLength = 0;
    // let visualQuestion = 0;
    if (categoryQues) {
      categoryQues.map((q, v) => {
        if (q?.input === "always_ask") {
          // console.log(q)
          running_total += 1;
          CatLength += 1;
          total += 1;
          _seq[running_total] = {
            from: `always_ask ${q.id}`,
            quesType: categoryType,
            skip: false,
            sno: running_total,
            visualQuestion: total,
          };
          return null;
        }

        if (q?.input === "ask_if_not_found") {
          // setPrevFoundResponse
          const _empCat = employeeCategory.filter(
            (ec) => ec.name === q.name
          )?.[0]?.value;
          if (_empCat) {
            const _response = q.answers.filter((rq) => rq.name === _empCat)?.[0]
              ?.id;
            running_total += 1;
            CatLength += 1;
            total += 0;
            _seq[running_total] = {
              from: `ask_if_not_found ${q.id}`,
              quesType: categoryType,
              skip: true,
              sno: running_total,
              total,
              q_order: q?.order,
              visualQuestion: total,
            };
            _prevResp[running_total] = {
              id: q.id,
              response: _response,
              priority: q.priority,
            };
          } else {
            running_total += 1;
            CatLength += 1;
            total += 1;
            _seq[running_total] = {
              from: `ask_if_not_found  ${q.id}`,
              quesType: categoryType,
              skip: false,
              sno: running_total,
              visualQuestion: total,
            };
          }
          return null;
        }

        if (q?.input === "never_ask") {
          const _empCat = employeeCategory.filter(
            (ec) => ec?.name === q?.name
          )?.[0]?.value_id;
          // console.log(q)
          running_total += 1;
          CatLength += 1;
          total += 0;
          _seq[running_total] = {
            from: `never_ask  ${q.id}`,
            quesType: categoryType,
            skip: true,
            sno: running_total,
            visualQuestion: total,
          };

          _neverAskResp[running_total] = {
            id: q.id,
            response: _empCat ? _empCat : null,
            priority: q.priority,
          };
          return null;
        }
        // employee category

        // employee persona in employee permission
        // employeePermissionExtraDet?.persona

        if (!q?.input) {
          running_total += 1;
          CatLength += 1;
          total += 1;
          _seq[running_total] = {
            from: `undefined`,
            quesType: categoryType,
            skip: false,
            sno: running_total,
            visualQuestion: total,
          };
        }
        return null;
      });
    }

    setPrevFoundResponse(_prevResp);
    setNeverAskResponse(_neverAskResp);

    // console.log("questions", questions)

    let quesLength = 0;
    if (questions) {
      questions.map((q, v) => {
        // if DTE ask those if matchin the id in employee category
        // "id": "15ff32d0-256d-42e0-82fb-b2c83c8d6b79",
        //   "name": "DTE",

         const _empCat = [employeeCategory.filter(
          (ec) => ec?.name_id === "15ff32d0-256d-42e0-82fb-b2c83c8d6b79"
        )?.[0]?.value_id,
        employeeCategory.filter(
          (ec) => ec?.name_id === "e322040c-13ae-427b-8765-4f759a341eaa"
        )?.[0]?.value_id]

        if (q?.role) {
          // so if there is role in the question it just checks for role nothing else
          if (hasMatch(q?.role, _empCat)) {
            // console.log("This list included role", _empCat)
            // Item exists in the array
            running_total += 1;
            quesLength += 1;
            total += 1;
            // console.log("Item ADDED! DTE ***************************", running_total, _empCat);
            _seq[running_total] = {
              from: `question for DTE role ADDED ${_empCat}`,
              quesType: questionsType,
              skip: false,
              sno: running_total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
            // skip if not founnd
          }
           else {
            // console.log("This list included rolesList?.length > 0 and no empcat", _empCat)
            running_total += 1;
            quesLength += 1;
            // total += 0
            // console.log("Item NOT ADDED! DTE ***************************", running_total, _empCat);
            _seq[running_total] = {
              from: `question for DTE role NOT ADDED ${_empCat}`,
              quesType: questionsType,
              skip: true,
              sno: running_total,
              total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
          }
        }
        // console.log("Item found! DTE *************************** BINARY", q?.order);
        // console.log("Item found! DTE *************************** BINARY", q?.binary);
        // console.log("Item found! DTE *************************** BINARY", employeePermissionExtraDet?.persona);
        // console.log(q?.order)

        if (
          q?.binary === true &&
          employeePermissionExtraDet?.persona === "Culture Leader"
        ) {
          // Item exists in the array

          running_total += 1;
          quesLength += 1;
          total += 1;
          // console.log("Item ADDED! === Culture Leader *************************** BINARY", running_total, employeePermissionExtraDet?.persona);

          _seq[running_total] = {
            from: `question for binary ADDED ${employeePermissionExtraDet?.persona}`,
            quesType: questionsType,
            skip: false,
            sno: running_total,
            q_order: q?.order,
            visualQuestion: total,
          };
          return null;
        } else if (
          q?.binary === true &&
          employeePermissionExtraDet?.persona !== "Culture Leader"
        ) {
          // Item exists in the array
          running_total += 1;
          quesLength += 1;
          // if (q?.order === 50) {
          // 	console.log("#########################################################################", running_total)
          // 	console.log("q?.binary", q?.binary)
          // 	console.log("employeePermissionExtraDet?.persona", employeePermissionExtraDet?.persona)
          // 	console.log((q?.binary === true && employeePermissionExtraDet?.persona === "Culture Leader")? "true": "false")
          // 	console.log((q?.binary === true && employeePermissionExtraDet?.persona !== "Culture Leader")? "true": "false")
          // 	console.log("#########################################################################")
          // }

          // total += 0
          // console.log("Item NOT ADDED! !== Culture Leader ************************************* BINARY", running_total, employeePermissionExtraDet?.persona);
          _seq[running_total] = {
            from: `question for binary NOT ADDED ${employeePermissionExtraDet?.persona}`,
            quesType: questionsType,
            skip: true,
            sno: running_total,
            total,
            q_order: q?.order,
            visualQuestion: total,
          };
          return null;
        }
        // console.log("checkRole", checkRole);

        if (checkRole) {
          if (q?.role) {
            if (q?.role?.includes(checkRole)) {
              // console.log("included checkRole", checkRole);
              running_total += 1;
              quesLength += 1;
              total += 1;
              _seq[running_total] = {
                quesType: questionsType,
                from: "question for specific role",
                skip: false,
                sno: running_total,
                q_order: q?.order,
                visualQuestion: total,
              };
              return null;
            } else {
              // console.log("NO checkRole", checkRole);
              running_total += 1;
              quesLength += 1;
              _seq[running_total] = {
                quesType: questionsType,
                from: "question not inlcuded role specifics",
                skip: true,
                sno: running_total,
                total,
                q_order: q?.order,
                visualQuestion: total,
              };
              return null;
            }
          } else {
            // console.log("question without role ", checkRole);
            running_total += 1;
            quesLength += 1;
            total += 1;
            _seq[running_total] = {
              quesType: questionsType,
              from: "question without role",
              skip: false,
              sno: running_total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
          }
        } else {
          // console.log("no role checked ", checkRole);
          running_total += 1;
          quesLength += 1;
          total += 1;
          _seq[running_total] = {
            quesType: questionsType,
            from: "no role checked",
            skip: false,
            sno: running_total,
            q_order: q?.order,
            visualQuestion: total,
          };
          return null;
        }
      });
    }

    // console.log(employeePermissionExtraDet?.persona)

    let commLength = 0;
    if (askComments) {
      askComments.map((q, v) => {

        const _empCat = [employeeCategory?.filter(
          (ec) => ec?.name_id === "15ff32d0-256d-42e0-82fb-b2c83c8d6b79"
        )?.[0]?.value_id,
        employeeCategory?.filter(
          (ec) => ec?.name_id === "e322040c-13ae-427b-8765-4f759a341eaa"
        )?.[0]?.value_id]

        if (q?.role) {
          // so if there is role in the question it just checks for role nothing else
          if (hasMatch(q?.role, _empCat)) {
            // console.log("This list included role", _empCat)
            // Item exists in the array

            running_total += 1;
            quesLength += 1;
            commLength += 1;
            total += 1;

            _seq[running_total] = {
              from: `This question is added due to conditional role formatting`,
              quesType: commentsType,
              skip: false,
              sno: running_total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
            // skip if not founnd
          }
           else {
            // console.log("This list included rolesList?.length > 0 and no empcat", _empCat)
            running_total += 1;
            quesLength += 1;
            // total += 0
            // console.log("Item NOT ADDED! DTE ***************************", running_total, _empCat);
            _seq[running_total] = {
              from: `question NOT ADDED, due to exclusion from role`,
              quesType: commentsType,
              skip: true,
              sno: running_total,
              total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
          }
        }



        if (q?.binary && q?.binary === true) {
          // if binary ask only to Culture Leaders
          if (employeePermissionExtraDet?.persona === "Culture Leader") {
            // Item exists in the array
            running_total += 1;
            quesLength += 1;
            commLength += 1;
            total += 1;
            // console.log("Item Comment Culture Leader && Binary *************************** BINARY", running_total, employeePermissionExtraDet?.persona);
            _seq[running_total] = {
              from: `question for comment binary ${q?.binary} and culture leader ${employeePermissionExtraDet?.persona}`,
              quesType: commentsType,
              skip: false,
              sno: running_total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
          }
        } else {
          if (employeePermissionExtraDet?.persona !== "Culture Leader") {
            running_total += 1;
            quesLength += 1;
            commLength += 1;
            total += 1;
            // console.log("Item Comment ADDED! Non Binary and No Culture Leader *************************** NON BINARY", running_total, employeePermissionExtraDet?.persona);
            _seq[running_total] = {
              from: `question for comment non binary ${q?.binary} and non culture leader ${employeePermissionExtraDet?.persona}`,
              quesType: commentsType,
              skip: false,
              sno: running_total,
              q_order: q?.order,
              visualQuestion: total,
            };
            return null;
          }
        }

        running_total += 1;
        quesLength += 0;
        // total += 0
        // console.log("Item  Comment NOT ADDED! !== Culture Leader ************************************* BINARY", running_total, employeePermissionExtraDet?.persona);
        _seq[running_total] = {
          from: `no binary information ${q?.binary} and personal detai ${employeePermissionExtraDet?.persona}`,
          quesType: commentsType,
          skip: true,
          sno: running_total,
          total,
          q_order: q?.order,
          visualQuestion: total,
        };
        return null;
      });
    }

    running_total += 1;
    // submit questin
    _seq[running_total] = {
      from: `submit button`,
      quesType: submitButton,
      skip: false,
      sno: running_total,
      total,
      visualQuestion: total,
    };

    setTotalQuestions({
      total: total,
      runningTotal: running_total,
      questions: quesLength,
      category: CatLength, // add primaryCategory
      comments: commLength,
      sequence: _seq,
    });
  }, [
    questions,
    categoryQues,
    askComments,
    checkRole,
    employeeCategory,
    employeePermissionExtraDet,
  ]);

  // console.log(totalQuestions?.sequence?.[questionNo])
  // console.log("questionNo", questionNo)
  // console.log("totalQuestions?.sequence", totalQuestions?.sequence)
  // console.log("totalQuestions.category", totalQuestions)
  // console.log("totalQuestions", totalQuestions)
  // if (totalQuestions?.sequence) {
  // 	// console.log(totalQuestions.sequence.filter(qq => qq.skip === false).map(cc => cc.sno))
  // }

  // if (employeeCategory) {
  // 	console.log("employeeCategory DTE", employeeCategory.filter(ec => ec?.name_id === '15ff32d0-256d-42e0-82fb-b2c83c8d6b79')?.[0]?.value_id)
  // }

  // console.log("Item found! employeePermissionExtraDet?.persona", employeePermissionExtraDet?.persona)
  // if the number of questions changes based on responses
  // reset or remove the older questions which were answered
  useEffect(() => {
    setResetAnswers(true);
  }, [totalQuestions.total]);

  useEffect(() => {
    if (resetAnswers && questionAnswers?.categories !== undefined) {
      setResetAnswers(false);
      let _quesAns = questionAnswers;

      setQuestionAnswers({
        categories: _quesAns?.categories,
        questions: {},
        comments: {},
      });
    }
  }, [resetAnswers, questionAnswers, dispatch, totalQuestions, questionNo]);

  function nextQuestion() {
    let _questionNo = questionNo + 1;

    if (_questionNo <= totalQuestions?.runningTotal) {
      // Run this code block if _questionNo is less than or equal to totalQuestions.runningTotal
      while (_questionNo <= totalQuestions?.runningTotal) {
        // console.log("next ------------------------------");
        if (totalQuestions.sequence[_questionNo]?.skip) {
          _questionNo++;
          // console.log("next _questionNo", _questionNo);
        } else {
          // console.log("next ------------------------------++++++++++++++++++", _questionNo);
          setQuestionNo(_questionNo);
          setVisualQuestionNo(totalQuestions.sequence[_questionNo]?.visualQuestion);
          return;
        }
      }
    }

    // If we reach this point, it means _questionNo is greater than totalQuestions.runningTotal
    setQuestionNo(totalQuestions?.runningTotal);
    setComplete(true);
  }
  // console.log(totalQuestions.sequence)
  // 	console.log("skip question", totalQuestions.sequence[questionNo])

  function prevQuestion() {
    let _questionNo = questionNo - 1;
    while (_questionNo >= 1) {
      if (totalQuestions.sequence[_questionNo]?.skip) {
        _questionNo--;
      } else if (totalQuestions.sequence[_questionNo]?.skip === false) {
        setQuestionNo(_questionNo);
        setVisualQuestionNo(totalQuestions.sequence[_questionNo]?.visualQuestion);
        return;
      }
    }
  }

  const goForward = () => {
    if (briefResponse?.high_answer >= questionNo) {
      setPrevResponse(null);
      setDirection(1);
      if (questionNo + 1 <= totalQuestions.runningTotal) {
        nextQuestion();
        setComplete(false);
        setCheckResponse(true);
      } else {
        setComplete(true);
      }
    }
  };

  // console.log("totalQuestions", totalQuestions)

  const goBack = () => {
    if (questionNo > 1) {
      setDirection(0);
      setPrevResponse(null);
      setComplete(false);
      prevQuestion();
      if (questionNo === totalQuestions.runningTotal) {
        if (questionNo === briefResponse?.current_question) {
          dispatch({
            type: UPDATE_BRIEF_RESPONSE,
            payload: {
              current_question: 0,
              current_answer: 0,
              complete: false,
            },
          });
        } else {
          // prevQuestion();
          setCheckResponse(true);
        }
      } else {
        // prevQuestion();
        setCheckResponse(true);
      }
    }
  };

  function PreCloseSurvey() {
    if (questionNo + 1 <= totalQuestions.runningTotal) {
      nextQuestion();
      return false;
    } else {
      if (!ruleFeedbackComplete) {
        // console.log("no feedback close this")
        setAskFeedback(true);
        return false;
      }
      setAskFeedback(false);
      setComplete(true);
      return true;
    }
  }

  // console.log(get_brief_response?.response?.stored_response?.high_answer > 2)
  // console.log(get_brief_response?.id)
  // console.log(get_brief_response?.response)
  // console.log("storedresponseFound", storedResponseFound)
  useEffect(() => {
    if (get_brief_response?.response?.id) {
			setNoResponse(false)
		} else {
			setNoResponse(true)
		}
  }, [get_brief_response]);

  useEffect(() => {
    // setStoredResponseFound(false);
    // if (VALIDATION_TOKEN) {
    //   if (get_brief_response?.response?.validation_token) {
    //     if (get_brief_response?.response?.stored_response) {
    //       setStoredResponseFound(true);
    //     }
    //   }
    // }
    if (get_brief_response?.validation_token) {
      // console.log("get_brief_response?.validation_token", get_brief_response?.validation_token)
      setValidationToken(get_brief_response?.validation_token);
      if (get_brief_response?.response?.stored_response?.high_answer > 2) {
        // console.log("get_brief_response?.response?.stored_response?.high_answer > 2", get_brief_response?.response?.stored_response?.high_answer)
        setStoredResponseFound(true);
      } else {
        setStoredResponseFound(false);
      }
    } else {
      setStoredResponseFound(false);
      setValidationToken(false);
    }
  }, [
    get_brief_response.validation_token,
    get_brief_response.response.stored_response,
  ]);

  // // primary Response
  // useEffect(() => {
  // 	setStoredResponseFound(false)
  // 	if (get_brief_response?.response?.validation_token) {
  // 		if (get_brief_response?.response?.stored_response?.high_answer > 4) {
  // 			// if clicked once it showing_stored_response === true, show only if its false
  // 			// it resets to false on refresh
  // 			if (showingStoredResponse === false) {
  // 				if (VALIDATION_TOKEN === get_brief_response?.response?.validation_token) {
  // 					setStoredResponseFound(true)
  // 				}
  // 			}
  // 		}
  // 	}

  // }, [get_brief_response.response, showingStoredResponse]);

  // console.log(get_brief_response?.response?.stored_response?.answers?.categories?.[questionNo])
  // console.log(get_brief_response?.response?.stored_response?.answers?.questions?.[questionNo])
  // console.log(get_brief_response?.response?.stored_response?.answers?.comments?.[questionNo])

  useEffect(() => {
    setPrevResponse(null);
    if (questionNo) {
      const QueType = totalQuestions?.sequence?.[questionNo]?.quesType;
      // console.log(QueType)
      if (QueType === categoryType) {
        setPrevResponse(get_brief_response?.answers?.categories?.[questionNo]);
      }
      if (QueType === questionsType) {
        setPrevResponse(get_brief_response?.answers?.questions?.[questionNo]);
      }
      if (QueType === commentsType) {
        setPrevResponse(get_brief_response?.answers?.comments?.[questionNo]);
      }
    }
  }, [totalQuestions, get_brief_response.answers, questionNo]);

  // console.log("get_brief_response?.high_answer", get_brief_response?.high_answer)
  // console.log("totalQuestions?.total", totalQuestions?.total)
  // console.log("questionNo", questionNo)
  // console.log("setShowPrev", showPrev)
  // console.log("totalQuestions?.sequence", totalQuestions?.sequence)
  console.log("questionAnswers", questionAnswers)

  useEffect(() => {
    setShowPrev(false);
    setShowNext(false);
    setBriefResponse(get_brief_response);
    if (questionNo) {
      if (questionNo > 1) {
        if (totalQuestions?.sequence?.[questionNo - 1]) {
          setShowPrev(true);
        }

        if (questionNo <= get_brief_response?.high_answer) {
          if (questionNo < totalQuestions?.runningTotal) {
            setShowNext(true);
          }
        }
      }
    }
    setSurveyDeleted(
      get_brief_response?.to_delete ? get_brief_response?.to_delete : false
    );
    setSurveyDeletedOn(
      get_brief_response?.deleted_on ? get_brief_response?.deleted_on : false
    );
    setSurveyStatus(
      get_brief_response?.survey_status ? get_brief_response?.survey_status : 0
    );
    setShowIntro(get_brief_response?.intro_completed);

    if (get_brief_response?.response?.stored_response?.answers) {
      setQuestionAnswers(
        get_brief_response?.response?.stored_response?.answers
      );
    }

    setSurveyFeedbackResp(
      get_brief_response?.response?.stored_response?.feedback
    );
    setSurveyModularResp(
      get_brief_response?.response?.stored_response?.modular_response
    );
    setSurveyPersonalityResp(
      get_brief_response?.response?.stored_response?.survey_personality
    );
  }, [get_brief_response, questionNo, totalQuestions]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (token) {
      dispatch({
        type: SG_START_BRIEF_SURVEY,
        payload: `?token=${token}`,
      });
    }
  }, [dispatch, token]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setDelayedShow(get_brief_response?.pending);
    }, 1000);
    return () => clearTimeout(timer1);
  }, [get_brief_response]);

  // console.log("totalQuestions?.total", totalQuestions?.total)
  // console.log("totalQuestions", totalQuestions)
  // console.log("questionAnswers", questionAnswers)

  // if (questionAnswers) {
  //   let questCount = 0
  //   let catCount = 0
  //   let commCount = 0
  //   if (questionAnswers?.questions) {
  //     questCount = Object.values(questionAnswers?.questions)?.length
  //   }
  //   if (questionAnswers?.categories) {
  //     catCount = Object.values(questionAnswers?.categories)?.length
  //   }
  //   if (questionAnswers?.comments) {
  //     commCount = Object.values(questionAnswers?.comments)?.length
  //   }
  //   // console.log("questCount + catCount + commCount", questCount + catCount + commCount)
  //   // console.log("totalQuestions?.total > questCount + catCount + commCount ",(
  //   //   totalQuestions?.total >
  //   //   (questCount + catCount + commCount)
  //   // ))
  // }


  function submitAnswer() {
    const CompleteCategories = {
      ...questionAnswers?.categories,
      ...prevFoundResponse,
      ...neverAskResponse,
    };
    if (CompleteCategories) {
      CompleteCategories[0] = primaryCategory;
    }
    let questCount = 0;
    let catCount = 0;
    let commCount = 0;
    if (questionAnswers) {
      if (questionAnswers?.questions) {
        questCount = Object.values(questionAnswers?.questions)?.length;
      }
      if (questionAnswers?.categories) {
        catCount = Object.values(questionAnswers?.categories)?.length;
      }
      if (questionAnswers?.comments) {
        commCount = Object.values(questionAnswers?.comments)?.length;
      }
    }
    if (totalQuestions?.total && questionAnswers?.questions) {
      if (totalQuestions?.total > questCount + catCount + commCount) {
        setSubmitError(
          "There was error in the answers, mostly likely there was an interruption when you were trying to complete the survey. Please try again!"
        );
        return;
      }
    }
    let _completeCategories = [];
    if (CompleteCategories) {
      _completeCategories = Object.values(CompleteCategories);
    }

    let _completeQuestions = [];
    if (questionAnswers.questions) {
      _completeQuestions = Object.values(questionAnswers.questions);
    }

    let _completeComments = [];
    if (questionAnswers.comments) {
      _completeComments = Object.values(questionAnswers.comments);
    }
    // const surveyComponents = get_brief_response?.modular_response;
    // console.log(CompleteCategories)
    // console.log(questionAnswers)
    const payload = {
      survey_token: briefResponse?.survey_token,
      debrief_schedule: briefResponse?.debrief_schedule,
      employee: briefResponse?.employee,
      response: {
        questions: {
          questions: _completeQuestions,
          categories: _completeCategories,
          comments: _completeComments,
        },
        total_question_map: totalQuestions,
        feedback: surveyFeedbackResp,
        survey_personality: surveyPersonalityResp,
        modular_response: surveyModularResp,
      },
      full_response: {
        isBrowser: isBrowser,
        isMobile: isMobile,
        browser: deviceDetected.browserName,
        deviceType,
        osVersion,
        osName,
        deviceDetected: deviceDetected,
        response: {
          questions: {
            questions: _completeQuestions,
            categories: _completeCategories,
            comments: _completeComments,
          },
          total_question_map: totalQuestions,
          // feedback: ruleFeedback,
          feedback: surveyFeedbackResp,
          survey_personality: surveyPersonalityResp,
          modular_response: surveyModularResp,
        },
      },
    };
    // console.log(payload)

    dispatch({
      type: SG_POST_BRIEF_RESPONSE,
      payload,
    });

    dispatch({
      type: RESET_VALIDATION_TOKEN,
      payload: null,
    });
    dispatch({
      type: CLEAR_ALL,
      payload: null,
    });

    setThankMessage(true);
  }

  // console.log("briefResponseId", briefResponseId)

  const IntroQuestionAnswer = () => {
    let CatLength = 0;
    if (categoryQues) {
      CatLength = categoryQues?.length;
    }
    const NewValidationToken = uuidv4();

    const payload = {
      current_question: CatLength > 0 ? 0 : 1,
      current_answer: 0,
      high_answer: 0,
      answers: {
        categories: {},
        questions: {},
        comments: {},
      },
      complete: false,
      intro_completed: true,
      validation_token: NewValidationToken,
      modular_response: get_brief_response?.modular_response,
    };

    setDirection(1);
    if (!validationToken) {
      localStorage.setItem("validation_token", NewValidationToken);
      dispatch({
        type: NEW_VALIDATION_TOKEN,
        payload: NewValidationToken,
      });
    }

    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload: {
        total_categories: CatLength,
        total_questions: totalQuestions?.runningTotal,
        current_category: CatLength > 0 ? 1 : 0,
        category_answer: 0,
        high_category: 0,
        total_question_map: totalQuestions,
        ...payload,
        validation_token: NewValidationToken,
      },
    });
    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          query: `?token=${token}&start=true`,
          validation_token: NewValidationToken,
          payload: { ...payload, validation_token: NewValidationToken },
        },
      });
    }
    setShowIntro(true);
    nextQuestion();
  };

  const saveCategoryAnswer = (response) => {
    // console.log(response)
    let _answers = questionAnswers.categories;
    setDirection(1);
    if (_answers?.questionNo) {
      _answers.questionNo = response;
    } else {
      _answers[questionNo] = response;
    }

    setQuestionAnswers({
      categories: _answers,
      questions: questionAnswers?.questions,
      comments: questionAnswers?.comments,
    });

    let _complete = PreCloseSurvey();
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: {
        questions: questionAnswers?.questions,
        comments: questionAnswers?.comments,
        categories: _answers,
      },
      complete: _complete,
      intro_completed: showIntro,
      validation_token: validationToken,
      feedback: surveyFeedbackResp,
      survey_personality: surveyPersonalityResp,
      modular_response: surveyModularResp,
    };

    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });

    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          query: `?token=${token}`,
          validation_token: validationToken,
          stored_response: payload,
        },
      });
    }
  };

  function saveQuestionAnswer(response) {

    let _answers = questionAnswers.questions;
    setDirection(1);

    if (_answers?.questionNo) {
      _answers.questionNo = response;
    } else {
      _answers[questionNo] = response;
    }
    setQuestionAnswers({
      categories: questionAnswers?.categories,
      comments: questionAnswers?.comments,
      questions: _answers,
    });

    let _complete = PreCloseSurvey();
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: {
        categories: questionAnswers?.categories,
        comments: questionAnswers?.comments,
        questions: _answers,
      },
      complete: _complete,
      intro_completed: showIntro,
      validation_token: validationToken,
      feedback: surveyFeedbackResp,
      survey_personality: surveyPersonalityResp,
      modular_response: surveyModularResp,
    };
    // dispatch({
    // 	type: UPDATE_BRIEF_RESPONSE,
    // 	payload
    // });

    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          query: `?token=${token}`,
          validation_token: validationToken,
          stored_response: payload,
        },
      });
    }
  }

  const saveCommentAnswer = (response) => {
    let _answers = questionAnswers.comments;
    setDirection(1);

    if (_answers?.questionNo) {
      _answers.questionNo = response;
    } else {
      _answers[questionNo] = response;
    }

    setQuestionAnswers({
      categories: questionAnswers?.categories,
      questions: questionAnswers?.questions,
      comments: _answers,
    });

    let _complete = PreCloseSurvey();
    setComplete(_complete);
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: {
        categories: questionAnswers?.categories,
        questions: questionAnswers?.questions,
        comments: _answers,
      },
      complete: _complete,
      intro_completed: showIntro,
      validation_token: validationToken,
      feedback: surveyFeedbackResp,
      survey_personality: surveyPersonalityResp,
      modular_response: surveyModularResp,
    };
    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });
    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          query: `?token=${token}`,
          validation_token: validationToken,
          stored_response: payload,
        },
      });
    }
    goForward()
  };

  useEffect(() => {
    if (get_errors?.errors?.error?.request?.response) {
      let RESP = JSON.parse(get_errors?.errors?.error?.request?.response);
      setSubmitError(RESP?.detail);
    }
  }, [get_errors]);

  useEffect(() => {
    // setPageLoading(true);
    setDefaultMessage({
      title: "Looking for Survey",
      subTitle: "Please wait while we try and get that survey loaded",
    });
    const timer1 = setTimeout(() => {
      setDefaultMessage({
        title: "No Survey details found",
        subTitle:
          "Unfortunately we have not been retrive any information on this survey",
      });
    }, 5000);
    return () => clearTimeout(timer1);
  }, [noResponse]);

  const getTextColor = () => {
    return "#4048FF";
  };

  // console.log("totalQuestions?.category", totalQuestions?.category)
  // console.log(questions)

  const get_french_name = () => {
    const french_name = get_brief_response.response?.emp_survey_structure?.survey_structure.fr
    if(french_name){
      return french_name
    }

    return get_brief_response.response?.survey_name
  }

  const get_english_name = () => {
    const en_name = get_brief_response.response?.emp_survey_structure?.survey_structure.name
    if(en_name){
      return en_name
    }

    return get_brief_response.response?.survey_name
  }

  const Main_section = () => {
    // const introText = "Innerlogic processes your personal data and aggregates responses on behalf of OTP. Your personal data is never directly personally identifiable to your NSO and is only ever presented in an anonymized fashion. Please consult our privacy statement for further details."
    const consentText =
      "I hereby give consent for Own the Podium or designate research partner to use anonymized and aggregated user data only in the further development of culture of excellence programming for the benefit of the high performance sport community in Canada. Do you agree with your personal data being processed as described above and in our privacy statement? You must click accept in order to give your feedback.";
    const EndTextFR =
      "Vous avez répondu à toutes les questions de ce sondage. Merci de soumettre vos réponses.";

    const customAgreement = (
      <Agreement
        agreementText={consentText}
        color={"black"}
        fontSize={"18px"}
        handleConsentCb={(checked) => setConsentProvided(checked)}
        type={"OTP"}
        hasConsented={consentProvided}
      />
    );

    const IntroText = () => {
      if (language == 1) {
        return (
          <div style={{ fontSize: 18 }}>
            <p>
              {get_french_name()} s’engage à
              l’optimisation de la culture de haute performance de son programme
              de l’équipe nationale. La santé physique et psychologique et la
              sécurité de tous les participants et participantes au programme
              sont aussi importants que la performance. Ainsi,{" "}
              {get_french_name()} appuyé par À nous le
              podium, entreprend l’audit et l’évaluation de sa culture sportive.{" "}
            </p>
            <p>
              Compte tenu de votre rôle au sein du programme, vous avez été
              choisi•e pour participer à un sondage. L’évaluation de la culture
              est un exercice complexe ; veuillez prévoir de 30 à 45 minutes
              pour compléter le sondage. Vos réponses seront sauvegardées si
              vous avez besoin d’y revenir plus tard. Toutefois, le lien pour le
              sondage ne fonctionnera qu’à partir de l’appareil que vous avez
              utilisé pour y accéder. Ceci assure l’anonymat et limite chaque
              répondant•e à une seule soumission au sondage.{" "}
            </p>

            <p>
              Vos réponses honnêtes et réfléchies sont essentielles pour
              comprendre l’état actuel de la culture du programme. Toutes les
              réponses demeureront anonymes pour favoriser des réflexions
              sincères et franches.{" "}
            </p>
          </div>
        );
      }

      return (
        <div style={{ fontSize: 18 }}>
          <p>
            {get_english_name()} is committed to
            optimizing the high performance culture of its National Team
            Program. The physical and psychological health and safety of
            everyone involved in the program are of equal importance to
            performance. As such, {get_english_name()} is
            engaging in a sport culture audit and assessment supported by Own
            the Podium.{" "}
          </p>
          <p>
            Given your role within the program, you have been selected to
            participate in a survey. Assessing culture is complex; therefore,
            you can anticipate the survey taking approximately 30-45 minutes to
            complete. Your responses will be saved if you need to come back at a
            later date, but you will only be able to access the survey from the
            link on the original device you used. This is to ensure anonymity
            while restricting each respondent to one submission only.{" "}
          </p>

          <p>
            Your honest and thoughtful responses are critical for understanding
            the current cultural status of the program. All responses will
            remain anonymous to encourage openness and candor while answering
            each question.{" "}
          </p>
        </div>
      );
    };

    if (!showIntro) {
      // ask for existing data in storage
      if (storedResponseFound) {
        return (
          <OtpExistingDataFound
            show={true}
            setQuestionNo={setQuestionNo}
            setVisualQuestionNo={setVisualQuestionNo}
            setShowIntro={setShowIntro}
            token={token}
            briefResponseId={briefResponseId}
            validationToken={validationToken}
            storedResponse={get_brief_response?.response?.stored_response}
          />
        );
      }
      return (
        <Intro
          key={questionNo}
          title={
            language == 1
              ? "Outil d’évaluation et d’audit de la culture du sport de haute performance"
              : "High Performance Sport Culture Assessment & Audit Tool "
          }
          intro={<IntroText />}
          setShowIntro={setShowIntro}
          saveAnswer={IntroQuestionAnswer}
          consentText={consentText}
          consentCheckedCb={(response) => setConsentProvided(response)}
          customElement={customAgreement}
          surveyEnabled={consentProvided}
          elementRef={elementRef}
          language={language}
        />
      );
    }

    if (complete) {
      return (
        <End
          key={questionNo}
          title={""}
          language={language}
          submitAnswer={() => submitAnswer()}
          intro={
            language === 1
              ? EndTextFR
              : "You have completed all the questions in this survey. Please submit your responses"
          }
        />
      );
    }
    if (totalQuestions?.sequence[questionNo]?.quesType === submitButton) {
      return (
        <End
          key={questionNo}
          title={""}
          language={language}
          submitAnswer={() => submitAnswer()}
          intro={
            language === 1
              ? EndTextFR
              : "You have completed all the questions in this survey. Please submit your responses"
          }
        />
      );
    }

    if (askFeedback) {
      return (
        <CommentsFeedback
          ruleFeedback={ruleFeedback}
          setRuleFeedback={setRuleFeedback}
          setRuleFeedbackComplete={setRuleFeedbackComplete}
          submitAnswer={() => submitAnswer()}
          setAskFeedback={setAskFeedback}
          setComplete={setComplete}
        />
      );
    }

    if (totalQuestions?.sequence[questionNo]?.quesType === categoryType) {
      return (
        <CategoryQuestion
          key={questionNo}
          save={saveCategoryAnswer}
          checkResponse={checkResponse}
          setCheckResponse={setCheckResponse}
          name={get_brief_response.response.survey_name}
          prevResponse={prevResponse}
          data={categoryQues[questionNo - 1]}
          employeeCategory={employeeCategory}
          language={language}
        />
      );
    }

    if (totalQuestions?.sequence[questionNo]?.quesType === questionsType) {
      return (
        <QuestionTemplate
          key={questionNo}
          // save={(r) => saveQ(r)}
          saveQuestionAnswer={saveQuestionAnswer}
          response={prevResponse}
          name={language==1?get_french_name():get_english_name()}
          question={
            questions.filter(
              (q) => q.order === totalQuestions?.sequence[questionNo]?.q_order
            )[0]
          }
          language={language}
          onClick={goBack}
          showNext={showNext}
          goForward={goForward}
        />
      );
    }
    if (totalQuestions?.sequence[questionNo]?.quesType === commentsType) {
      return (
        <CommentTemplate
          key={questionNo}
          // save={(r) => saveQ(r)}
          saveCommentAnswer={saveCommentAnswer}
          response={prevResponse}
          language={language}
          question={
            askComments.filter(
              (q) => q.order === questionNo - totalQuestions?.category
            )[0]
          }
          onClick={goBack}
          showNext={showNext}
          goForward={goForward}
        />
      );
    }

    return <NoToken />;
  };

  if (delayedShow) {
    return (
      <SwitchTransition>
        <CSSTransition
          key={`${questionNo} ${showIntro} ${askFeedback}`}
          timeout={TRANSITIONSPEED}
          classNames={SlideDirection[1]}
        >
          <ModuleContainer className="module-wrapper">
            <LogoAnimation />
          </ModuleContainer>
        </CSSTransition>
      </SwitchTransition>
    );
  }

  // set this before no token or no response, this is set by useState
  if (thankMessage) {
    const ThanksMessageFR = "Merci pour votre réponse au sondage";
    return (
      <Container>
        <SwitchTransition>
          <CSSTransition
            key={`${questionNo} ${showIntro} ${askFeedback}`}
            timeout={TRANSITIONSPEED}
            classNames={SlideDirection[direction]}
          >
            <SurveyMessage
              message={
                language == 1
                  ? ThanksMessageFR
                  : "Thanks for your survey response"
              }
              onTime={new Date()}
              language={language}
            />
          </CSSTransition>
        </SwitchTransition>
      </Container>
    );
  }

  if (!token) {
    return <NoToken {...defaultMessage} />;
  }
  if (noResponse) {
    // console.log(
    //   "i am here missing something !token | !get_brief_response.id | noResponse",
    //   noResponse
    // );
    return (
      <SwitchTransition>
        <CSSTransition
          key={`${questionNo} ${showIntro} ${askFeedback}`}
          timeout={TRANSITIONSPEED}
          classNames={SlideDirection[1]}
        >
          {defaultMessage.title == "Looking for Survey" ? (
            <div
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader active inline>
                Loading
              </Loader>
            </div>
          ) : (
            <NoToken {...defaultMessage} />
          )}
        </CSSTransition>
      </SwitchTransition>
    );
  }

  if (submitError) {
    return (
      <Container>
        <SwitchTransition>
          <CSSTransition
            key={`${questionNo} ${showIntro} ${askFeedback}`}
            timeout={TRANSITIONSPEED}
            classNames={SlideDirection[direction]}
          >
            <SurveyMessage
              onTime={surveyDeletedOn}
              message={submitError}
              language={language}
            />
          </CSSTransition>
        </SwitchTransition>
      </Container>
    );
  }

  if (surveyDeleted) {
    return (
      <Container>
        <SwitchTransition>
          <CSSTransition
            key={`${questionNo} ${showIntro} ${askFeedback}`}
            timeout={TRANSITIONSPEED}
            classNames={SlideDirection[direction]}
          >
            <SurveyMessage
              onTime={surveyDeletedOn}
              status={surveyStatus}
              language={language}
            />
          </CSSTransition>
        </SwitchTransition>
      </Container>
    );
  }

  return (
    <Container
      height={
        showIntro ? "100vh" : elementRef.current?.clientHeight + 100 + "px"
      }
    >
      <SwitchTransition>
        <CSSTransition
          key={`${questionNo} ${showIntro} ${askFeedback}`}
          timeout={TRANSITIONSPEED}
          classNames={SlideDirection[direction]}
        >
          {Main_section()}
        </CSSTransition>
      </SwitchTransition>
      {showLanguage ? (
        <Language
          selected={language}
          setSelected={setLanguage}
          color={getTextColor()}
        />
      ) : (
        ""
      )}

      <CounterContainer>
        <Counter>
          <div>
            <Title>CAAT</Title>
            {visualQuestionNo || 1 }{" "}
            {language == 1 ? "de" : "of"} {totalQuestions?.total} questions
          </div>
        </Counter>
        <Icon
          name="chevron up"
          disabled={!showPrev}
          style={{ cursor: showPrev ? "pointer" : "not-allowed" }}
          onClick={goBack}
        />
        <Space />
        <Icon
          name="chevron down"
          style={{ cursor: showNext ? "pointer" : "not-allowed", zIndex: 2 }}
          disabled={!showNext}
          onClick={goForward}
        />
      </CounterContainer>

      <Shapes />
    </Container>
  );
};

export default OtpSurvey;

const Container = styled.div`
  height: ${(props) => props.height};
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Barlow", sans-serif;
  position: relative;
`;

const CounterContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;
  background-color: grey;
  border-radius: 5px;
  z-index: 1;

  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 14px;
  }
`;

const Counter = styled.div`
  padding: 5px 10px 5px 10px;

  font-size: 12px;
  border-radius: 7px;
  margin-right: 10px;

  @media only screen and (min-width: 992px) {
    padding: 5px 10px;
    font-size: 12px;
    margin-right: 25px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;

  @media only screen and (min-width: 992px) {
    margin-bottom: -5px;
    font-size: 14px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;

const ModuleContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 100vh;
  overflow: hidden;

  @media (max-height: 875px) and (max-width: 768px) {
    align-items: flex-start;
    top: 125px;
    max-height: calc(100vh - 125px);
  }

  @media (max-height: 550px) {
    align-items: flex-start;
    top: 75px;
    max-height: calc(100vh - 75px);
  }
`;
